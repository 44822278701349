
import {Component, Prop, Vue} from "vue-property-decorator";
import {IProduct} from "@/apis/product/types";
import config from "@/config";

@Component({})
export default class ProductBasic extends Vue {
  config = config;
  // 新增修改表单
  @Prop(Object) modelForm!: IProduct;
  // 新增修改表单rules
  @Prop(Object) rules!: any;

  /**
   * 完成
   *
   */
  handleFinish() {
    this.$emit('Finish', this.modelForm)

  }
  /**
   * 下一步，上一步
   * @param number
   *
   */
  handleNext(tab: string) {
    this.$emit('Next', this.modelForm,tab)
  }
  created() {
  }
}
